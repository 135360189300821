import React from 'react'
import Navbar from '../components/Navbar'
import Heroimg2 from '../components/Heroimg2'
import Footer from '../components/Footer'
import Form from '../components/Form'
import { motion, useScroll } from 'framer-motion'
import { Helmet } from 'react-helmet'
import SEO from '../components/SEO'
const Contact = () => {
    const { scrollYProgress } = useScroll()
    return (
        <>
            <SEO title={"Harsh Tech || Contact"} description={"This is a Contact page"} link={"https://www.harshnikharge.online/#/contact"} />
            <motion.div
                className='scroll'
                style={{
                    scaleX: scrollYProgress,
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    height: 10,
                    background: "Yellow",
                    transformOrigin: "0%",
                    zIndex: "100"
                }}
            >

            </motion.div>
            <motion.div
                initial={{ width: 0 }}
                animate={{ width: "100%" }}
                exit={{ width: window.innerWidth, transition: { duration: 0.2 } }}
            >
                <Navbar />
                <Heroimg2 heading="Contact us" text="Lets heve a chat" />
                <Form />
                <Footer />
            </motion.div>
        </>
    )
}

export default Contact
