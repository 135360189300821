import React from 'react'
import Navbar from '../components/Navbar'
import Heroimg2 from '../components/Heroimg2'
import Footer from '../components/Footer'
import Work from '../components/Work'
import { motion, useScroll } from 'framer-motion'
import { Helmet } from 'react-helmet'
import SEO from '../components/SEO'


const Project = () => {
    const { scrollYProgress } = useScroll()
    return (
        <>
            <SEO title={"Harsh Tech || Project"} description={"This is a Project page"} link={"https://www.harshnikharge.online/#/project"} />
            <motion.div
                className='scroll'
                style={{
                    scaleX: scrollYProgress,
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    height: 10,
                    background: "Yellow",
                    zIndex: "100",
                    transformOrigin: "0%"
                }}

            >

            </motion.div>
            <motion.div
                initial={{ width: 0 }}
                animate={{ width: "100%" }}
                exit={{ width: window.innerWidth, transition: { duration: 0.1 } }}
            >
                <Navbar />
                <Heroimg2 heading="Projects" text="Some of my recent project" />
                <Work />
                <Footer />
            </motion.div>
        </>
    )
}

export default Project;
