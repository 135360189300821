import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import Home from '../routes/Home'
import Project from '../routes/Project'
import Contact from '../routes/Contact'
import About from '../routes/About'
import { AnimatePresence } from 'framer-motion'
import PreLoader from './PreLoader'
import { HelmetProvider } from 'react-helmet-async'

function AnimatedRoutes() {
	const location = useLocation()

	return (

		<HelmetProvider>
			<AnimatePresence>
				<Routes location={location} key={location.pathname}>
					<Route path='/' element={<Home />} />
					<Route path='/project' element={<Project />} />
					<Route path='/contact' element={<Contact />} />
					<Route path='/about' element={<About />} />
				</Routes>
			</AnimatePresence>
		</HelmetProvider>

	)
}

export default AnimatedRoutes
