import React from 'react'
import Navbar from '../components/Navbar'
import Heroimg from '../components/Heroimg'
import Footer from '../components/Footer'
import HomeMarquee from '../components/Home/HomeMarquee'
import Counter from '../components/Counter'
import { Helmet } from 'react-helmet-async'
import SEO from '../components/SEO'

const Home = ({ title, number }) => {
    return (
        <>
            <SEO title={"Harsh Tech || Home"} description={"This is a home page"} link={"https://www.harshnikharge.online/#/"} />
            <Navbar />
            <Heroimg />
            <HomeMarquee />
            <Counter title={title} number={number} />
            <Footer />
        </>
    )
}

export default Home
