import React from 'react'
import Navbar from '../components/Navbar'
import Heroimg2 from '../components/Heroimg2'
import Footer from '../components/Footer'
import Aboutdata from '../components/Aboutdata'
import MainTab from '../components/tab/MainTab'
import { motion, useScroll } from 'framer-motion'
import { Helmet } from 'react-helmet'
import SEO from '../components/SEO'

const About = () => {
    const { scrollYProgress } = useScroll()
    return (
        <>
            <SEO title={"Harsh Tech || About"} description={"This is a About page"} link={"https://www.harshnikharge.online/#/about"} />
            <motion.div
                className='scroll'
                style={{
                    scaleX: scrollYProgress,
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    height: 10,
                    background: "Yellow",
                    transformOrigin: "0%",
                    zIndex: "100"
                }}
            >
            </motion.div>

            <motion.div
                initial={{ width: 0 }}
                animate={{ width: "100%" }}
                exit={{ width: window.innerWidth, transition: { duration: 0.2 } }}
            >
                <Navbar />
                <Heroimg2 heading="About Me" text="Im a friendly Front-End Developer." />
                <Aboutdata />
                <MainTab />
                <Footer />
            </motion.div>
        </>
    )
}

export default About
