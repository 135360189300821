import React from 'react'
import { Helmet } from 'react-helmet';

const SEO = ({ title, description, link }) => {
	return (
		<Helmet>
			<meta charSet='utf-8' />
			<title>{title}</title>
			<meta
				name="keywords"
				content="Harsh Tech,Harsh Tech Contact, Harsh Nikharge,Web Development, React Developer, Frontend Solutions, Custom Web Design, UI/UX Design, Responsive Websites, Modern Web Applications"
			/>
			<meta name="author" content="Harsh Tech" />
			<meta name="robots" content="index, follow" />
			<meta
				name="description"
				content={description}
			/>
			<link rel="canonical" href={link} />
		</Helmet>
	)
}

export default SEO